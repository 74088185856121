var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"Simple Table"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-autocomplete',{staticClass:"mr-5 status-filter-autocomplete",attrs:{"label":"Invoice status","multiple":"","item-text":"title","item-value":"value","items":_vm.invoiceStatuses,"name":Math.random(),"autocomplete":Math.random(),"clearable":""},model:{value:(_vm.filters.status__in),callback:function ($$v) {_vm.$set(_vm.filters, "status__in", $$v)},expression:"filters.status__in"}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.due_date,'LL')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getStatusTitle(item.status)))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created)))])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.total_amount.toFixed(2))+" "+_vm._s(_vm._f("currency")(item.currency)))])]}}])}),_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }