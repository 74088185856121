import DHLLogo from "@/assets/couriers/logos/dhl.png";
import TNTLogo from "@/assets/couriers/logos/tnt-logo.gif";
import UPSLogo from "@/assets/couriers/logos/ups.png";


export const shipmentStatuses = [
    {value: 'new', title: 'New', color: 'info'},
    {value: 'processed', title: 'Processed', color: 'warning'},
    {value: 'paid', title: 'Paid', color: 'success'},
]

export const orderStatuses = [
    {value: 'new', title: 'New', color: 'info'},
    {value: 'processed', title: 'Processed', color: 'warning'},
    {value: 'paid', title: 'Paid', color: 'success'},
]

export const pickUpTimeEarliest = [
    {value: '09:00', title: '9:00am'},
    {value: '09:30', title: '9:30am'},
    {value: '10:00', title: '10:00am'},
    {value: '10:30', title: '10:30am'},
    {value: '11:00', title: '11:00am'},
    {value: '11:30', title: '11:30am'},
    {value: '12:00', title: '12:00pm'},
    {value: '12:30', title: '12:30pm'},
    {value: '13:00', title: '1:00pm'},
    {value: '13:30', title: '1:30pm'},
    {value: '14:00', title: '2:00pm'},
    {value: '15:00', title: '3:00pm'},
    {value: '16:00', title: '4:00pm'},
]
export const pickUpTimeLatest = [
    {value: '11:00', title: '11:00am'},
    {value: '12:00', title: '12:00pm'},
    {value: '12:30', title: '12:30pm'},
    {value: '13:00', title: '1:00pm'},
    {value: '13:30', title: '1:30pm'},
    {value: '14:00', title: '2:00pm'},
    {value: '14:30', title: '2:30pm'},
    {value: '15:00', title: '3:00pm'},
    {value: '15:30', title: '3:30pm'},
    {value: '16:00', title: '4:00pm'},
    {value: '16:30', title: '4:30pm'},
    {value: '17:00', title: '5:00pm'},
    {value: '17:30', title: '5:30pm'},
    {value: '18:00', title: '6:00pm'},
]

export const paidBy = [
    {value: 'shipper', title: 'Shipper'},
    {value: 'third_party', title: 'Third party'},
    {value: 'recipient', title: 'Recipient'},
]

export const weightUnit = [
    {value: 'lb', title: 'lb'},
    {value: 'kg', title: 'kg'},
    {value: 'oz', title: 'oz'},
    {value: 'g', title: 'g'},
]

export const unit = [
    {value: 'cm', title: 'cm'},
    {value: 'inches', title: 'inches'},
    {value: 'mm', title: 'mm'},
    {value: 'm', title: 'm'},
    {value: 'ft', title: 'ft'},
    {value: 'yd', title: 'yd'},
]

export const purpose = [
    {value: 'sale', title: 'Sale'},
    {value: 'gift', title: 'Gift'},
    {value: 'merchandise', title: 'Merchandise'},
    {value: 'personal', title: 'Personal'},
    {value: 'sample', title: 'Sample'},
    {value: 'return', title: 'Return'},
    {value: 'repair', title: 'Repair'},
]

export const termsOfTrade = [
    // {value: 'dat', title: 'DAT'},
    {value: 'ddu', title: 'DDU'},
    {value: 'ddp', title: 'DDP'},
    // {value: 'dap', title: 'DAP'},
]


export const courierLogos = {
    tnt: TNTLogo,
    dhl: DHLLogo,
    ups: UPSLogo
}


export const invoiceStatuses = [
    {value: 'approved', title: 'Pending', color: 'warning'},
    {value: 'paid', title: 'Paid', color: 'success'}
]


export const dhlAccountTypes = [
    {value: 'europe', title: 'Europe'},
    {value: 'usa', title: 'USA'},
    {value: 'asia', title: 'Asia'},
]
