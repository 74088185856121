<template>
  <v-container
      id="regular-tables"
      fluid
      tag="section"
  >
    <v-card
        icon="mdi-clipboard-text"
        title="Simple Table"
        class="px-5 py-3"
    >

      <v-toolbar flat>
        <v-autocomplete
            label="Invoice status"
            class="mr-5 status-filter-autocomplete"
            multiple
            item-text="title"
            item-value="value"
            :items="invoiceStatuses"
            v-model="filters.status__in"
            :name="Math.random()"
            :autocomplete="Math.random()"
            clearable
        />
        <v-spacer/>
      </v-toolbar>

      <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :loading="loading"
          @page-count="pageCount = $event"
          @click:row="handleClick"
          class="table"
      >
        <template v-slot:item.due_date="{item}">
          <span>{{ item.due_date | date('LL') }}</span>
        </template>

        <template v-slot:item.status="{item}">
          <v-chip :color="getStatusColor(item.status)">{{ getStatusTitle(item.status)}}</v-chip>
        </template>

        <template v-slot:item.created="{item}">
          <span>{{ item.created | date }}</span>
        </template>

        <template v-slot:item.total_amount="{item}">
          <span>{{ item.total_amount.toFixed(2) }} {{ item.currency | currency }}</span>
        </template>

      </v-data-table>

      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </v-card>
  </v-container>
</template>

<script>
import {invoiceStatuses} from '@/choices';
import Invoice from '@/models/Invoice';


export default {
  data() {
    return {
      invoiceStatuses,
      page: 1,
      pageCount: 10,
      itemsPerPage: 10,
      loading: true,
      filters: {},
      headers: [
        {
          text: 'Reference',
          value: 'reference'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Created',
          value: 'created'
        },
        {
          text: 'Due date',
          value: 'due_date'
        },
        {
          text: 'Total amount',
          value: 'total_amount'
        }
      ],
    }
  },
  computed: {
    items() {
      return Invoice.all();
    }
  },
  methods: {
    handleClick(item) {
      this.$router.push({name: 'Invoice', params: {id: item.id}})
    },
    getStatusColor(status) {
      return invoiceStatuses.find(choice => choice.value === status).color
    },
    getStatusTitle(status) {
      return invoiceStatuses.find(choice => choice.value === status).title
    },
    transformFilters(filters) {
      return Object.entries(filters).reduce((acc, [key, val])=> {
        acc[key] = Array.isArray(val) ? val.join(',') : val;
        return acc
      }, {});
    },
  },
  watch: {
    filters: {
      async handler(value) {
        const transformedFilters = this.transformFilters(value);
        await Invoice.deleteAll();
        this.loading = true;
        await Invoice.api().get('/api/v1/accounting/invoices/', {params: transformedFilters});
        this.loading = false;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
.table {
  cursor: pointer;
}
.status-filter-autocomplete {
  max-width: 300px;
}
</style>
